import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { UsersApi } from '@corezilla/api/features/auth/services/users.api';
import { AuthUser } from '@corezilla/api/features/auth/interfaces/auth-user';
import {
  ApiError,
  COREZILLA_API_CONFIG,
  EndpointBuilder,
} from '@corezilla/api';
import { LoginError } from '@corezilla/api/features/auth/services/error/login.error';
import { AuthUserError } from '@corezilla/api/features/auth/services/error/auth-user.error';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  private readonly config = inject(COREZILLA_API_CONFIG);
  private readonly http = inject(HttpClient);
  private readonly usersApi = inject(UsersApi);

  get authUrl(): string {
    return EndpointBuilder.toUrl([this.config.apiConfUrl, 'auth']);
  }

  get usersUrl(): string {
    return EndpointBuilder.toUrl([this.config.apiConfUrl, 'users']);
  }

  public login(
    email: string,
    password: string,
    rememberMe: boolean = false
  ): Observable<AuthUser> {
    const url = EndpointBuilder.toUrl([this.authUrl, 'login']);
    return this.http
      .post(url, {
        username: email,
        password: password,
        _remember_me: rememberMe,
      })
      .pipe(
        map((response: any) => response as AuthUser),
        catchError((error: ApiError) => throwError(() => new LoginError(error)))
      );
  }

  public getUser(
    headers: HttpHeaders | { [p: string]: string | string[] } | undefined
  ): Observable<AuthUser> {
    const url = EndpointBuilder.toUrl([this.authUrl, 'get-user']);
    return this.http
      .get(url, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response as AuthUser),
        catchError((error: ApiError) =>
          throwError(() => new AuthUserError(error))
        )
      );
  }

  public logout(): Observable<void> {
    const url = EndpointBuilder.toUrl([this.authUrl, 'logout']);
    return this.http
      .post(url, {})
      .pipe(map((response: any) => response as void));
  }

  public updatePassword(
    userId: number,
    data: { oldPassword: string; newPassword: string }
  ): Observable<AuthUser> {
    const input = {
      oldPassword: data.oldPassword,
      password: data.newPassword,
    } as any;
    const url = EndpointBuilder.toUrl([
      this.usersUrl,
      userId.toString(),
      'password-change',
    ]);
    return this.http
      .post(url, input)
      .pipe(map((response: any) => response as AuthUser));
  }

  public updateUser(data: AuthUser): Observable<AuthUser> {
    return this.usersApi.update(data);
  }
}
