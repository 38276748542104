import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '@shared/components/card/card.component';
import { ButtonComponent } from '@flowkit/button';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordApiService } from '@core/services/password-api.service';
import { catchError, of } from 'rxjs';
import {
  PasswordResetFormComponent,
  PasswordResetFormResultType,
} from '@features/auth/components/password-reset/password-reset-form/password-reset-form.component';
import { AlertService } from '@flowkit/alert';

type TokenValidationStatusType = 'validating' | 'valid' | 'invalid';

@Component({
  selector: 'app-password-reset',
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    PasswordResetFormComponent,
  ],
  template: `
    <app-card class="flex">
      @if (tokenValidationStatus() === 'validating') {
        <span class="heading-title--small"
          >We are validating your request...</span
        >
      } @else if (tokenValidationStatus() === 'invalid') {
        <div class="mb-4 font-medium">
          <span class="heading-title--small"
            >We are sorry, but your token has expired.</span
          >
        </div>
        <button
          flkButton
          class="w-full"
          (click)="navigateToPasswordForgotten()">
          Password forgotten
        </button>
      } @else if (tokenValidationStatus() === 'valid') {
        <div class="mb-4 font-medium">
          <span class="heading-title--small underline--primary"
            >Password reset</span
          >
        </div>
        <app-password-reset-form
          [loading]="loading()"
          (submitted)="onSubmitted($event)"></app-password-reset-form>
      }
    </app-card>
  `,
})
export class PasswordResetComponent implements OnInit {
  /**
   * Token that is part of the route e.g. /auth/password-reset/MY_TOKEN
   */
  token: string;

  /**
   * Status of the token validation
   * validation = token is in validation against backend
   * invalid = token has expired
   * valid = token is valid -> user can change the password
   */
  tokenValidationStatus = signal<TokenValidationStatusType>('validating');

  loading = signal(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passwordApiService: PasswordApiService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];

    if (!this.token) {
      this.tokenValidationStatus.set('invalid');
      return;
    }

    this.validateToken();
  }

  onSubmitted(data: PasswordResetFormResultType) {
    if (this.loading()) {
      return;
    }
    this.loading.set(true);
    this.passwordApiService
      .reset(data.password, this.token)
      .pipe(catchError(() => this.handlePasswordResetError()))
      .subscribe(() => this.handlePasswordResetSuccess());
  }

  navigateToPasswordForgotten(): void {
    this.router.navigate(['auth', 'password-forgotten']);
  }

  private validateToken() {
    this.passwordApiService
      .validateToken(this.token)
      .pipe(catchError(() => this.handleValidateTokenError()))
      .subscribe(() => this.handleValidateTokenSuccess());
  }

  private handleValidateTokenError() {
    this.tokenValidationStatus.set('invalid');
    return of();
  }
  private handleValidateTokenSuccess() {
    this.tokenValidationStatus.set('valid');
  }

  private handlePasswordResetSuccess() {
    this.loading.set(false);
    this.router.navigate(['auth', 'login']);
  }

  private handlePasswordResetError() {
    this.loading.set(false);
    this.alertService.showError({
      title: 'Error',
      message: 'Could not update your password. Please try it again.',
    });
    return of();
  }
}
