import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as AuthActions from '@core/states/auth/auth.actions';
import {
  catchError,
  exhaustMap,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@core/states/app.state';
import * as AuthSelectors from '@core/states/auth/auth.selectors';
import { Router } from '@angular/router';
import { LOGIN_PATH } from '@features/auth/auth.routes';
import { AuthApi, AuthUser } from '@corezilla/api';
import { SkipErrorRedirectHeader } from '@core/interceptors/error.interceptor';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private authApi: AuthApi,
    private router: Router
  ) {}

  /**
   * Effect to login user
   * **/
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap(({ email, password, rememberMe }) =>
        this.authApi.login(email, password, rememberMe).pipe(
          map(user => AuthActions.loginSuccess({ user })),
          catchError(error => of(AuthActions.loginError({ error })))
        )
      )
    );
  });

  /**
   * Effect to handle successful login
   * **/
  onLoginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(() => this.router.navigate(['/process-definitions']))
      );
    },
    { dispatch: false }
  );

  /**
   * Effect to logout user
   * **/
  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      exhaustMap(() =>
        this.authApi.logout().pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError(error => of(AuthActions.logoutError({ error })))
        )
      )
    );
  });

  /**
   * Effect to handle successful logout
   * **/
  onLogoutSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutSuccess),
      map(() => AuthActions.removeAuthUser({ skipRedirect: false }))
    );
  });

  /**
   * Effect to remove user from auth state
   * **/
  onRemoveUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.removeAuthUser),
        tap(({ skipRedirect }) => {
          if (skipRedirect) {
            return;
          }
          this.router.navigate([LOGIN_PATH]);
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Effect to handle password update of current authenticated user
   * **/
  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateAuthUserPassword),
      exhaustMap(({ oldPassword, newPassword }) => {
        const authUser = this.store.selectSignal(
          AuthSelectors.selectUser
        )() as AuthUser;
        return this.authApi
          .updatePassword(authUser.id, { oldPassword, newPassword })
          .pipe(
            map(updated => AuthActions.updateAuthUserSuccess({ updated })),
            catchError(error => of(AuthActions.updateAuthUserError({ error })))
          );
      })
    );
  });

  /**
   * Effect to update authenticated user
   * **/
  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateAuthUser),
      exhaustMap(({ toUpdate }) => {
        const authUser = this.store.selectSignal(
          AuthSelectors.selectUser
        )() as AuthUser;
        return this.authApi.updateUser({ ...authUser, ...toUpdate }).pipe(
          map(updated => AuthActions.updateAuthUserSuccess({ updated })),
          catchError(error => of(AuthActions.updateAuthUserError({ error })))
        );
      })
    );
  });

  /**
   * Helper method to check if user is logged in
   * It tries to get the current user from the store, if not available it tries to fetch it from the backend
   * **/
  public isLoggedIn$(skipErrorRedirect = false): Observable<boolean> {
    return this.getCurrentUser$(skipErrorRedirect).pipe(map(user => !!user));
  }

  private getCurrentUser$(
    skipErrorRedirect = false
  ): Observable<AuthUser | undefined> {
    const currentUser = this.store.selectSignal(AuthSelectors.selectUser)();

    if (currentUser) {
      return of(currentUser);
    }

    return this.authApi
      .getUser(skipErrorRedirect ? SkipErrorRedirectHeader : {})
      .pipe(
        tap(user => this.store.dispatch(AuthActions.setAuthUser({ user }))),
        catchError(error => {
          this.store.dispatch(AuthActions.setAuthError(error));
          return throwError(() => error);
        })
      );
  }
}
