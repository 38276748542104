<form
  [formGroup]="formGroup"
  class="flex flex-col !pb-0"
  appEnterKey
  (enterKey)="submit()">
  <mat-form-field class="mb-3 w-full" appearance="outline" floatLabel="always">
    <mat-label>{{ emailLabel }}</mat-label>
    <input matInput formControlName="email" data-cy="email" />
    @if (formGroup.get('email')?.invalid) {
      <mat-error>
        @if (formGroup.get('email')?.getError('email')) {
          <span i18n="@@error.invalid-field">
            {{ emailLabel }} is invalid
          </span>
        } @else {
          <span i18n="@@error.required-field">
            {{ emailLabel }} is required
          </span>
        }
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field class="group w-full" appearance="outline" floatLabel="always">
    <mat-label i18n="@@auth.login.password">{{ passwordLabel }}</mat-label>
    <input
      matInput
      formControlName="password"
      [type]="hidePassword() ? 'password' : 'text'"
      data-cy="password" />
    <fa-icon
      matSuffix
      class="input__suffix"
      [icon]="iconClass()"
      (click)="hidePassword.set(!hidePassword())" />
      @if (formGroup.get('password')?.invalid) {
        <mat-error>
          @if (formGroup.get('password')?.getError('required')) {
            <span i18n="@@error.required-field">
              {{ passwordLabel }} is required
            </span>
          }
        </mat-error>
      }
  </mat-form-field>

  <div
    class="mb-12 flex justify-between gap-y-3 max-xs:flex-col xs:items-center">
    <mat-checkbox color="primary" formControlName="_remember_me">
      <span i18n="@@auth.login.remember" class="ml-1 w-max">Remember me</span>
    </mat-checkbox>

    <div class="max-xs:hidden">
      <ng-container [ngTemplateOutlet]="forgot" />
    </div>
  </div>

  <button
    flkButton
    class="h-12 w-full text-lg"
    [loading]="loading()"
    [disabled]="formGroup.invalid"
    (click)="submit()"
    data-cy="submit">
    Login
  </button>
  <div class="mt-2 xs:hidden">
    <ng-container [ngTemplateOutlet]="forgot" />
  </div>

  <!-- TODO: Disabled for now until feature completion -->
  <ng-template #forgot>
    <a
      class="inline-block w-full text-center text-sm hover:text-primary-400"
      i18n="@@auth.login.forgot"
      [routerLink]="['..', 'password-forgotten']"
    >
      Password forgotten?
    </a>
  </ng-template>
</form>
