import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '@shared/components/card/card.component';
import {
  PasswordForgottenFormComponent,
  PasswordForgottenFormGroupResultType,
} from '@features/auth/components/password-forgotten/password-forgotten-form/password-forgotten-form.component';
import { PasswordApiService } from '@core/services/password-api.service';
import { catchError, of } from 'rxjs';
import { Router } from '@angular/router';
import { ButtonComponent } from '@flowkit/button';
import { AlertService } from '@flowkit/alert';

@Component({
  selector: 'app-password-forgotten',
  imports: [
    ButtonComponent,
    CardComponent,
    CommonModule,
    PasswordForgottenFormComponent,
  ],
  template: `
    <app-card class="flex">
      <div class="mb-4 font-medium">
        <span
          class="heading-title--small underline--primary"
          i18n="@@auth.recovery.title"
          >Recover account</span
        >
      </div>
      <div class="mb-4">
        @if (hasBeenSent()) {
          <span>We've send you an email to {{ email() }}. </span>
        } @else {
          <span i18n="@@auth.recovery.sent">
            We will send you a confirmation email to this address.
          </span>
        }
      </div>
      @if (hasBeenSent()) {
        <button flkButton class="w-full" (click)="navigateToLogin()">
          Back to login
        </button>
      } @else {
        <app-password-forgotten-form
          [loading]="loading()"
          (submitted)="onSubmitted($event)"></app-password-forgotten-form>
      }
    </app-card>
  `,
})
export class PasswordForgottenComponent {
  /**
   * Represents login status of password forgotten request
   */
  loading = signal(false);

  /**
   * Identifies if password forgotten email has been sent
   */
  hasBeenSent = signal(false);

  /**
   * Email to which the password forgotten email has been sent
   */
  email = signal('');

  constructor(
    private alertService: AlertService,
    private passwordApiService: PasswordApiService,
    private router: Router
  ) {}

  onSubmitted(data: PasswordForgottenFormGroupResultType) {
    if (this.loading()) {
      return;
    }
    this.sendForgotPassword(data.email);
  }

  navigateToLogin() {
    this.router.navigate(['auth', 'login']);
  }

  private sendForgotPassword(email: string) {
    this.loading.set(true);
    this.passwordApiService
      .forgot(email)
      .pipe(catchError(() => this.handleError()))
      .subscribe(() => this.handleSuccess(email));
  }

  private handleError() {
    this.showErrorMessage();
    return of();
  }

  private handleSuccess(email: string): void {
    this.loading.set(false);
    this.hasBeenSent.set(true);
    this.email.set(email);
  }

  private showErrorMessage(): void {
    this.loading.set(false);
    this.alertService.showError({
      title: 'Error',
      message: `An error error occurred. Please try it again`,
    });
  }
}
