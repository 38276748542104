import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@core/states/app.state';
import * as AuthActions from '@core/states/auth/auth.actions';
import { ApiError } from '@corezilla/api';

export const SkipErrorRedirectKey = 'X-Skip-Error-Redirect';
export const SkipErrorRedirectHeader = new HttpHeaders({
  [SkipErrorRedirectKey]: '',
});

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skipRedirect = request.headers.has(SkipErrorRedirectKey);

    // Remove custom header, because not supported from backend
    const headers = request.headers.delete(SkipErrorRedirectKey);
    request = request.clone({ headers });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle 401 errors (unauthorized)
        if (error.status === 401) {
          this.store.dispatch(AuthActions.removeAuthUser({ skipRedirect }));
        }
        return throwError(() => new ApiError(error.error));
      })
    );
  }
}
