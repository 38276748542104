export const ApiErrorDefaultTitle = $localize`:@@apiError.title.default:Unknown API Error`;
export const ApiErrorDefaultMessage = $localize`:@@apiError.message.default:An unexpected error occurred`;

/**
 * Adding new titles here
 **/
export const ApiErrorTitlesMap: Record<string, string> = {
  FZCFAMAF401: $localize`:@@apiError.title.FZCFAMAF401:Login failed`,
};

/**
 * Adding new messages here
 **/
export const ApiErrorMessagesMap: Record<string, string> = {
  FZCFAMAF401: $localize`:@@apiError.message.FZCFAMAF401:Your credentials are incorrect.`,
};
