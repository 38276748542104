import { ApiErrorResponse } from '@corezilla/api/core/interfaces/api-error-response';

export class ApiError extends Error implements ApiErrorResponse {
  constructor(private response: ApiErrorResponse) {
    super(response.message);
  }

  get statusCode(): number {
    return this.response?.statusCode;
  }

  get errorCode(): string {
    return this.response?.errorCode;
  }
}
