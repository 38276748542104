import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { EnterKeyDirective } from '@core/directives/enter-key.directive';
import { ButtonComponent } from '@flowkit/button';

type PasswordForgottenFormGroupType = {
  email: FormControl<string | null>;
};

export type PasswordForgottenFormGroupResultType = {
  email: string;
};

@Component({
    selector: 'app-password-forgotten-form',
    imports: [
        CommonModule,
        ButtonComponent,
        FormsModule,
        MatError,
        MatFormField,
        MatInput,
        MatLabel,
        ReactiveFormsModule,
        EnterKeyDirective,
    ],
    templateUrl: './password-forgotten-form.component.html'
})
export class PasswordForgottenFormComponent implements OnInit {
  /**
   * Sets form in loading state
   */
  loading = input.required<boolean>();
  fieldLabel = 'Email';

  /**
   * Emits form group data when form has been submitted
   * Will not be triggered when form is still invalid or form is in loading state
   */
  @Output() submitted =
    new EventEmitter<PasswordForgottenFormGroupResultType>();

  formGroup: FormGroup<PasswordForgottenFormGroupType>;
  constructor(private fb: FormBuilder) {
    effect(() => {
      if (!this.loading()) {
        this.clearForm();
      }
    });
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (this.formGroup.invalid || this.loading()) {
      return;
    }
    // Because of the validators email field must be defined
    this.submitted.emit({ email: this.formGroup.value.email! });
  }

  private clearForm() {
    this.formGroup.controls['email']?.reset('');
  }
}
