import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@corezilla/api';
import { User } from '@corezilla/api/features/auth/interfaces/user';

@Injectable({ providedIn: 'root' })
export class UsersApi extends ApiService<User> {
  override apiUrl = this.config.apiConfUrl;
  override endpoint = 'users';

  constructor(http: HttpClient) {
    super(http);
  }
}
