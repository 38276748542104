import { ApiError } from '@corezilla/api';
import { LocalizedAlert } from '@core/helpers/localization/localized-alert';
import {
  ApiErrorDefaultMessage,
  ApiErrorDefaultTitle,
  ApiErrorMessagesMap,
  ApiErrorTitlesMap,
} from '@core/helpers/localization/api-error-localization-map';

export class ApiErrorLocalizationService {
  public static toTranslatedAlert(error: ApiError): LocalizedAlert {
    return {
      title: ApiErrorLocalizationService.toTranslatedTitle(error),
      message: ApiErrorLocalizationService.toTranslatedMessage(error),
    };
  }
  public static toTranslatedTitle(error: ApiError): string {
    return ApiErrorTitlesMap[error.errorCode] || ApiErrorDefaultTitle;
  }
  public static toTranslatedMessage(error: ApiError): string {
    return ApiErrorMessagesMap[error.errorCode] || ApiErrorDefaultMessage;
  }
}
