import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form/login-form.component';
import { CardComponent } from '@shared/components/card/card.component';
import { Store } from '@ngrx/store';
import { login } from '@core/states/auth/auth.actions';
import * as AuthSelectors from '@core/states/auth/auth.selectors';
import { AppState } from '@core/states/app.state';
import { AuthUser, LoginError } from '@corezilla/api';
import { AlertService } from '@flowkit/alert';
import { filter } from 'rxjs';
import { ApiErrorLocalizationService } from '@core/helpers/localization/api-error-localization.service';

@Component({
  selector: 'app-login',
  imports: [CommonModule, CardComponent, LoginFormComponent],
  template: `
    <app-card class="flex">
      <div class="mb-4 font-medium">
        <span class="heading-title--small" i18n="@@auth.login.title"
          >Login</span
        >
      </div>
      <app-login-form
        [loading]="viewStatus() === 'loading'"
        (submitEvent)="onLogin($event)">
      </app-login-form>
    </app-card>
  `,
})
export class LoginComponent implements OnInit {
  /** Current view status **/
  viewStatus = this.store.selectSignal(AuthSelectors.selectViewState);
  viewStateError = this.store.select(AuthSelectors.selectViewStateError);

  constructor(
    private store: Store<AppState>,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.viewStateError
      .pipe(filter(error => error instanceof LoginError))
      .subscribe(error =>
        this.alertService.showError(
          ApiErrorLocalizationService.toTranslatedAlert(error)
        )
      );
  }

  public onLogin(user: Partial<AuthUser>) {
    this.store.dispatch(
      login({
        email: user.email ?? '',
        password: user.password ?? '',
        rememberMe: user.rememberMe,
      })
    );
  }
}
